import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
import PortraitsTwo from '../../components/sub/portraitx2.js';
import Image from '../../components/image';
export const frontmatter = {
  title: "Becoming A Shape-Shifter: How To Beat Robots In Life & Work",
  date: "2018-10-15",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20181015_tsr.jpg",
  path: "2018-10-15-Shape-Shifter",
  teaserText: "We are living in the age of precision. We have been spoiled rotten with technology that brings predictability to all aspects of our lives."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="Becoming A Shape-Shifter: How To Beat Robots In Life & Work" description="We are living in the age of precision. We have been spoiled rotten with technology that brings predictability to all aspects of our lives." author='Roberto Ramos' keywords={['Robots']} />
      <ImageLandscape imageSrc="20181015_1.jpg" />
      <MDXTag name="p" components={components}>{`We are living in the age of precision. We have been spoiled rotten with technology that brings predictability to all aspects of our lives. From the time we are scheduled to arrive at our destination to friendly recommendations from AI-powered services that deliver familiarity, we have gladly outsourced life planning. And this for the most part has made our lives easier, saving us time, making us feel smarter, and with a bit more bandwidth to discover things we crave.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In the meantime the comfort zone brought on by this predictability also threatens to make us more dull and less divergent and creative in our thinking and ways. And this could be the symbolic downfall of the human wandering spirit, a vital quality that will become more valuable in the upcoming robotic era when an increasing number of tasks will be automated.`}</MDXTag>
      <MDXTag name="p" components={components}>{`And so we must rethink what it means to be human as we both embrace and compete with AI and robotic systems. This is by no means an apology for technology. It’s quite the opposite: the rise of smart systems will push humans to be a better version of themselves. This is no easy task, at both the personal and collective level, for it will mean pushing us beyond comfort zones to rethink and expand on our roles as creators and builders.`}</MDXTag>
      <ImageLandscape imageSrc="20181015_2.jpg" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Deprogramming Your Mind`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`If we do not want to be replaced by robots, we must begin by making sure we’re not thinking and acting like them. AI systems and robots, accelerating faster than at Morse’s law speed, excel in taking in copious amounts of information, gathering data and finding patterns of predictability. These systems are fueled by rote iteration. Where AI still fails is in the art of the subtext, passions and feelings that drive ideas that move people, cultural movements, markets and economies.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In a future when technology creates efficient platforms, it will be creative and innovative human catalysts in the form of outliers, those who defy the expectations of predictive models that will thrive. The following is a primer for escaping the robotic trap.`}</MDXTag>
      <ImageLandscape imageSrc="20181015_3.jpg" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Explore The Art Of The Unexpected`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Embrace uncertainty and nurture fluidity. Technology will seek to rule out information gaps, but new gaps will prevail in surprising folds. Modern day creators will thrive in seeing emerging patterns and future expressions in current tensions and the uncertainty of changing times. For instance, in fashion no algorithm could have predicted the eccentric boho chic movement unleashed by Alessandro Michelle at Gucci. However, if you observe closely and connect the societal and design dots, you can see how his return to a vintage romanticism is a natural response to today’s frenzied, tech-infused world.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Defy AI Suggestions`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Stand out from a rote world by embracing your personality. Every now and then push back on recommended playlists and movies. Go out of your way to discover new things. Tap into different influencer circles. Read different publications. Hardwire your brain to think outside of comfort zones. For it will it be in exposure to different things that you will learn to jump into lateral thinking and find ways to connect all the dots. This will make your processing input richer and your fodder for storytelling and experience stronger as well.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Make da Vinci Your Model`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Leonardo da Vinci thrived at the intersection of science and applied creativity. In the precision of science he found a transcendental form of design as well as a definite backdrop to channel his creativity. He explored this convergent yet often times conflicting border space, testing new techniques. His famous sfumato technique for instance, was all about the blurriness in areas of motion and transition. Learn from all of this and explore both your design and tech-centric side as well as your more tempestuous, inquisitive creative side. Learn programming, understand AI systems, and then ponder on the contradictions, writing, and crafting design experiences inspired by those findings.`}</MDXTag>
      <ImageLandscape imageSrc="20181015_4.jpg" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Amplify Your Human Side`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`As we process inputs from technology, it will be the human conversation framing it, designing it, programming it, and most importantly interpreting it and creating from it that will lead the way. Dial up your human side by doing a better job at listening, exploring, collaborating, mentoring and empowering to then posit human solutions to real problems and opportunities. And continue evolving as you do this, as robots learn fast.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    